﻿'use strict'

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeLazyLoad
    **/
    const initializeLazyLoad = function () {
        window.imgObserver = new IntersectionObserver((entries, self) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    lazyLoad(entry.target);
                    self.unobserve(entry.target);
                }
            });
        });

        addImages('.lazy-picture');

        const lazyLoad = (picture) => {
            const img = picture.querySelector('img');
            const sources = picture.querySelectorAll('source');

            sources.forEach((source) => {
                source.srcset = source.dataset.srcset;
            });
            img.src = img.dataset.src;
        }
    };

    const addImages = function (selector) {
        document.querySelectorAll(selector).forEach((picture) => {
            window.imgObserver.observe(picture);
        });
    };

    // Return an object exposing the public items
    return {
        initialize: initializeLazyLoad,
        addImages: addImages
    };

}());