﻿var jquery = require('jquery');
global.jQuery = global.$ = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');
var fancybox = require('@fancyapps/fancybox');
var topNavigation = require('./components/topNavigation');
var siteSearch = require('./components/siteSearch');
var cta = require('./components/cta');
var contactFooter = require('./components/contactFooter');
var lazyLoad = require('./components/lazyLoad');
var locationHighlight = require('./components/locationHighlight');
var popup = require('./components/popup');
//var loginPopup = require('./components/loginPopup');

$(function () {
    topNavigation.initialize();
    siteSearch.initialize();
    cta.initialize();
    contactFooter.initialize();
    lazyLoad.initialize();
    locationHighlight.initialize();
    popup.initialize();
    //loginPopup.initialize();

    $('#translate').click(function (e) {
        e.preventDefault();
        $('#google_translate_element_container').slideToggle(500, function () {
            $('html, body').animate({ scrollTop: $("#google_translate_element_container").offset().bottom }, 1000);
        });
    });
})
