'use strict'

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeCTA
    **/
    var initializeCTA = function () {
        let $ctaToggle = $("#cta-toggle");
        let $ctaExpand = $('.cta-expanded-content');
        let $expandLabel = $('.expand-label');
        if ($ctaExpand.hasClass('cta-open')) {
            // Toggle is open
            if (!$expandLabel.length) {
                $ctaToggle.append('<span class="expand-label">see less</span>');
            } else {
                $expandLabel.text('see less');
            }
        } else {
            // Toggle is closed
            if (!$expandLabel.length) {
                $ctaToggle.append('<span class="expand-label">see more</span>');
            } else {
                $expandLabel.text('see more');
            }
        }
        $ctaToggle.on("click", function () {
            $ctaExpand.toggleClass('cta-open')
            $ctaToggle.toggleClass('cta-open')

            // Check if the toggle is open or closed and update the label accordingly
            if ($ctaExpand.hasClass('cta-open')) {
                // Toggle is open
                if (!$expandLabel.length) {
                    $ctaToggle.append('<span class="expand-label">see less</span>');
                } else {
                    $expandLabel.text('see less');
                }
            } else {
                // Toggle is closed
                if (!$expandLabel.length) {
                    $ctaToggle.append('<span class="expand-label">see more</span>');
                } else {
                    $expandLabel.text('see more');
                }
            }
        });
    };

    // Return an object exposing the public items
    return {
        initialize: initializeCTA
    };

}());