'use strict'

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeSiteSearch
    **/
    var initializeSiteSearch = function () {
        var $searchToggle = $("#search-toggle");
        var $close = $(".close-search");
        $searchToggle.add($close).on("click", function () {
            var $searchDropdown = $('.header-search-expand');
            var $searchWrapper = $('.bl-header-search');
            $searchDropdown.toggleClass('search-open');
            $searchWrapper.toggleClass('search-open');
            var openSearchLabel = $('.header-search-btn').attr('data-open-aria-label');
            var closeSearchLabel = $('.header-search-btn').attr('data-close-aria-label');
            $('.header-search-btn').attr('aria-label', $searchDropdown.hasClass('search-open') ? closeSearchLabel : openSearchLabel);
            /*$searchDropdown.attr('aria-expanded', function (idx, attr) { return !JSON.parse(attr); });*/
        });
    };

    // Return an object exposing the public items
    return {
        initialize: initializeSiteSearch
    };

}());